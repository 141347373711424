import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import DefaultCloseIcon from '../../../shared/library/icons/close.svg';
import {PHONE} from '../../../shared/style/breakpoints';
import {BLACK, ASH, WHITE, GUNSMOKE, FOCUS_BLUE} from '../../../shared/style/colors';
import {BASE_TEXT, WEIGHT} from '../../style/typography.js';
import SimpleButton from '../../../shared/library/buttons/base/simple';
import {useSendAnalyticsEvent} from '../../../shared/enhancers/analytics-enhancer';
import {compose} from 'react-apollo';
import {withLocalStorage} from '../../../shared/enhancers/local-storage-enhancer';
import StackFileIcon from '../../../shared/library/icons/stack-file.svg';
import {GITHUB_STACK_FILE_MARKETPLACE} from '../../constants/link';

const Container = glamorous.div(
  {
    height: 95,
    display: 'flex',
    background: WHITE,
    border: `1px solid ${ASH}`,
    position: 'relative',
    borderRadius: 4,
    margin: '0 0 22px 0',
    padding: '0 30px 0 10px',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1,
    [PHONE]: {
      padding: '5px 10px',
      margin: 10
    },
    ':hover > svg': {
      '> g': {
        fill: GUNSMOKE,
        stroke: GUNSMOKE
      }
    }
  },
  ({customStyle}) => (customStyle ? customStyle : {})
);

const Heading = glamorous.p({
  ...BASE_TEXT,
  fontSize: '17px',
  fontWeight: WEIGHT.BOLD,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.53',
  color: BLACK,
  margin: '0 20px',
  marginBottom: 0,
  [PHONE]: {
    margin: 0,
    fontSize: '10px'
  }
});

const Title = glamorous.p({
  ...BASE_TEXT,
  fontSize: 14,
  fontWeight: WEIGHT.NORMAL,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.43,
  color: '#8d8d8d',
  margin: '0 20px',
  marginBottom: 0,
  [PHONE]: {
    margin: '2px 0 5px',
    fontSize: '10px'
  }
});

const IconWithText = glamorous.div({
  display: 'flex',
  background: WHITE,
  [PHONE]: {
    alignItems: 'center'
  }
});

const Text = glamorous.div({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
});

const Button = glamorous(SimpleButton)({
  minWidth: 115,
  width: 220,
  height: 40,
  letterSpacing: 'normal',
  [PHONE]: {
    height: 20,
    width: 70,
    minWidth: 70,
    padding: 0,
    fontSize: 10,
    position: 'absolute',
    bottom: 5,
    right: 5
  },
  ':focus': {
    color: WHITE,
    backgroundColor: FOCUS_BLUE
  }
}).withComponent('a');

const CloseIcon = glamorous(DefaultCloseIcon)({
  cursor: 'pointer',
  position: 'absolute',
  top: 8,
  right: 8,
  height: 10,
  width: 10,
  [PHONE]: {
    top: 5,
    right: 5,
    height: 7,
    width: 7,
    '> g': {
      fill: GUNSMOKE,
      stroke: GUNSMOKE
    }
  }
});

const StackFileIconStyled = glamorous(StackFileIcon)({
  width: 75,
  height: 75,
  pointerEvents: 'none',
  [PHONE]: {
    display: 'none'
  }
});

const PrivateStackShareCta = ({title, pageName, storageProvider, customStyle = {}}) => {
  const [showCTA, setShowCTA] = useState(false);
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const CTA_SEEN = `${pageName}StackFileCTA`;

  const handleDismissNotice = () => {
    setShowCTA(false);
    storageProvider.setItem(CTA_SEEN, true);
  };

  useEffect(() => {
    if (!storageProvider.getBoolean(CTA_SEEN)) {
      setShowCTA(true);
    }
  }, []);

  return (
    showCTA && (
      <Container customStyle={customStyle}>
        <CloseIcon onClick={handleDismissNotice} />
        <IconWithText>
          <StackFileIconStyled />
          <Text>
            <Heading>Get beautiful automated tech stack docs for your GitHub repos ✨</Heading>
            <Title>{title}</Title>
          </Text>
        </IconWithText>
        <Button
          onClick={() => sendAnalyticsEvent('stack_file_click_install_bot', {pageName: pageName})}
          href={GITHUB_STACK_FILE_MARKETPLACE}
        >
          Install the bot
        </Button>
      </Container>
    )
  );
};

PrivateStackShareCta.propTypes = {
  title: PropTypes.string,
  pageName: PropTypes.string,
  storageProvider: PropTypes.object,
  customStyle: PropTypes.object
};

export default compose(withLocalStorage('Stack File CTA', '1'))(PrivateStackShareCta);
